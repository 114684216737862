import React from "react";

const Login = () => {
  const APP_URL = window.location.origin;

  const LOGIN_URL = `https://uhx01.kratinmobile.com/uhx/identity/auth/dologin?callback=${APP_URL}/checklogin`;

  const login = () => {
    window.location.replace(LOGIN_URL);
  };

  return (
    <div className="form">
      <div className="form__container">
        <div className="form__box">
          <div className="form__left">
            <div className="form__padding">
              <div
                className="form__image"
                // src="../assets/kratin_vertical_logo.png"
              ></div>
            </div>
          </div>
          <div className="form__right">
            <div className="form__padding-right">
              <h1 className="form__title">Kratin Candidate Login (DEMO)</h1>
              <div className="loginbtn-container">
                <button onClick={login} className="btn loginBtn">
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

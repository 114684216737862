import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Output from "./Output";
import Processing from "./Processing";
import Error from "./Error";

const EditorFooter = ({
  code,
  lang_id,
  input,
  expOutput,
  credentials,
  submitTime,
  totalTime,
  status,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [haveExecuted, sethaveExecuted] = useState(false);
  const [customInput, setCustomInput] = useState("");
  const [results, setResults] = useState([]);
  const [output, setOutput] = useState([]);
  const [error, setError] = useState(false);
  const [err_txt, setErrTxt] = useState("");

  const navigate = useNavigate();

  // variables used in run function
  var interval, count;

  // if custom input is checked
  // set display to flex
  // else hide it
  const ci = document.getElementById("customInput");

  if (isChecked) {
    ci.style.display = "flex";
  } else {
    try {
      ci.style.display = "none";
    } catch {}
  }

  // sub function in run funcition
  // does the major work
  // posting and getting output
  // from judge api
  async function execute(i) {
    // is custom input is not enabled
    // or enabled
    // split the input and join with \n
    // so inputs are taken correctly
    // for all languages
    if (!isChecked) {
      var inp = input[i].split(" ");
      inp = inp.filter((x) => x !== "");
      inp = inp.join("\n");
    } else {
      if (i.includes(",")) {
        var inp = i.split(",");
        inp = inp.filter((x) => x !== "");
        inp = inp.join("\n");
      } else {
        var inp = i.split(" ");
        inp = inp.filter((x) => x !== "");
        inp = inp.join("\n");
      }
    }

    // options to post in judge0 api
    const options = {
      source_code: code,
      language_id: lang_id,
      number_of_runs: "1",
      stdin: inp,
      cpu_time_limit: "2",
      cpu_extra_time: "0.5",
      wall_time_limit: "5",
      memory_limit: "128000",
      stack_limit: "64000",
      max_processes_and_or_threads: "60",
      enable_per_process_and_thread_time_limit: false,
      enable_per_process_and_thread_memory_limit: false,
      max_file_size: "1024",
      enable_wait_result: true,
    };

    // post the code with lang_id
    // in judge api

    try {
      const tokenData = await axios.post(
        "https://democode.truliacare.com/execute/submissions",
        options
      );

      // wait for 5 secs for execution in judge
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // get method to get the output
      const result = await axios.get(
        `https://democode.truliacare.com/execute/submissions/${tokenData.data.token}`
      );

      // output from api
      // if contains error
      // show error
      // else go with result evaluation
      var err = result.data.status.description;
      var r = result.data.stdout;
      
      // check for compilation error in the api outpt
      if (err === "Compilation Error") {
        setErrTxt("Compilation Error");
        setError(true);
        // Set compile_output if available
        if (result.data.compile_output) {
          // Display compile_output in the frontend
          setErrTxt(result.data.compile_output);
        }
        clearInterval(interval);
        setProcessing(false);
        return;
      }
      
      // If the output consists of a runtime error (NZEC)
      if (err === "Runtime Error (NZEC)") {
        // Set stderr if available
        if (result.data.stderr) {
          // Display stderr in the frontend
          setErrTxt(result.data.stderr);
        }
        setError(true);
        clearInterval(interval);
        setProcessing(false);
        return;
      }

       //prevents from showing accepted if nothimg is printed.
       if (r === null) {
        setErrTxt("Print appropriate output");
        setError(true);
        clearInterval(interval);
        setProcessing(false);
        return;
      }

      // if output doesn't consist error and the stdout is not null
      // evaluate user output with expected outputs
      if (result.data.stderr === null && r !== null) {
        // set error false
        setError(false);

        // if its the last call for judge api
        // set processing to false
        // so outputs can be shown
        // and set the status
        // for usage in force logout
        if (i === input.length - 1) {
          setProcessing(false);
          if (
            results.every((val, i, arr) => val === "true") &&
            results.length !== 0
          ) {
            status("Accepted");
          } else {
            status("Rejected");
          }
        }

        // push result to output array
        output.push(r);

        // check if custom input is checked
        if (!isChecked) {
          // trim the spaces
          // for better matching of
          // user output and expOutputs
          if (r.trim() === expOutput[i].trim()) {
            results.push("true");
          } else {
            results.push("false");
          }

          // if custom input
          // no need to evaluate
          // just show results
        } else {
          results.push("true");
          setProcessing(false);
        }

        // if error in output
        // set error text received from
        // judge api
        // and enable the error html element
        // by setting error to true
      } else {
        // before showing error wait
        // to avoid collision between another run
        // and this get response
        await new Promise((resolve) => setTimeout(resolve, 2000));

        // push the result as false
        results.push("false");
        // set the error text
        setErrTxt(err);
        // error text shows run again, if the execution is in queue
        if (err === "In Queue" || err === "Processing") setErrTxt("Run again");
        // clear interval and don't execute further
        clearInterval(interval);
        // close the processing template
        setProcessing(false);
        // set the error template to display
        setError(true);
      }

      // if there is error in code
      // or syntax error
      // stop execution
      // and show compilation error
    } catch (e) {
      results.push("false");
      clearInterval(interval);
      await new Promise((resolve) => setTimeout(resolve, 2050));
      setErrTxt("Compilation Error");
      setError(true);
    }
  }

  // run function to execute code
  async function run() {
    // set empty to all arrays
    // for rerun they can have previous values
    // so better set them empty
    results.splice(0, results.length);
    output.splice(0, output.length);

    // running the process component
    setProcessing(true);
    // hide error template
    setError(false);
    // set true to show templates
    setIsShown(true);

    // run the setInterval
    // for the number of times
    // as much the number of elements
    // in inputs array
    // in short - run for number of test cases
    count = 0;
    if (isChecked) {
      // console.log(customInput);
      execute(customInput);
    } else {
      sethaveExecuted(true);
      // execute with intervals
      interval = setInterval(() => {
        execute(count);
        // increase count with each api call
        ++count;
        // if its the last call
        // clear interval
        if (count === input.length) {
          clearInterval(interval);
        }
      }, 2000);
    }
  }

  // submit function
  async function submitCode() {
    if (!haveExecuted) {
      window.alert("Run the test cases before submitting!");
    } else {
      // keeps track of status of execution of code
      var stat = "";
      // if all the elements in results array is true
      // code is accpeted for all given inputs
      if (
        results.every((val, i, arr) => val === "true") &&
        results.length !== 0
      ) {
        stat = "Accepted";
      } else {
        stat = "Rejected";
      }

      // keep the time the candidate used for coding
      var min = totalTime - parseInt(submitTime / 60);

      // submission data
      const submission = {
        name: credentials[0],
        email: credentials[1],
        code: code,
        submitTime: Date(Date.now()).toString(),
        inputs: `[${input}]`,
        outputs: `[${output}]`,
        expectedOutputs: `[${expOutput}]`,
        status: stat,
      };

      // ask before submission
      const confirm = window.confirm("Are You sure, you want to submit the code? ");
      if (confirm) {
        // submit the code
        axios
          .post("https://democode.truliacare.com/backend/submission", submission)
          .then((response) => {
            //alert the user
            window.alert(
              "Your submission has been recorded!\nYou can submit again or logout."
            );
          });
      }
    }
  }

  // toggle the custom input
  const handleCheckbox = () => {
    setOutput([]);
    setIsShown(false);
    setIsChecked(!isChecked);
  };

  // save the custom input
  const saveCustomInput = (e) => {
    setCustomInput(e.target.value);
  };

  return (
    <div className="footer-container editor-bgclr">
      <div className="editor-footer ">
        <div className="custom-input cl-white">
          <input
            type="checkbox"
            name="customInput"
            className="checkbox"
            style={{ display: "block" }}
            onChange={handleCheckbox}
          />
          <label htmlFor="customInput" style={{ color: "#7c7975" }}>
            Custom Input
          </label>
        </div>
        <a onClick={run} className=" btn runBtn">
          {isChecked ? "Run </>" : "Run TestCases"}
        </a>
        <a className="btn submitBtn " onClick={submitCode} role="button">
          Submit
        </a>
      </div>
      <div style={{ display: "none" }} id="customInput">
        <textarea
          className="input-txt bg-gray cl-white"
          onChange={saveCustomInput}
          placeholder="give input with space, don't use (,)"
        ></textarea>
      </div>
      {/* if there is no error show the output */}
      {isShown && !isProcessing && !error ? (
        <Output
          input={!isChecked ? input : customInput}
          yourOutput={output}
          expOutput={!isChecked ? expOutput : ""}
          results={results}
          isChecked={isChecked}
        />
      ) : null}
      {/* if there is error show the error template */}
      {isShown && isProcessing && !error ? <Processing /> : null}
      {error ? <Error err_txt={err_txt} /> : null}
    </div>
  );
};

export default EditorFooter;

import React, { useEffect, useState } from "react";
import Split from "react-split";
import ControlledEditor from "@monaco-editor/react";
import Header from "./Header";
import EditorHeader from "./EditorHeader";
import EditorFooter from "./EditorFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import Pagenotfound from "./Pagenotfound";
import {driver} from 'driver.js';
import "driver.js/dist/driver.css";

const CodeEditor = () => {
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);
  const [code, setcode] = useState("");
  const [defaultValue, setDefaultValue] = useState("//Let's Code");
  const [resetDefaultValue, setResetDefaultValue] = useState(false);
  const [lang, setLang] = useState("");
  const [lang_id, setLangId] = useState("");
  const [submitTime, setSubmitTime] = useState("");
  const [status, setStatus] = useState("");
  const [question, setQuestion] = useState([]);
  const [quesns_available, setQuesnsAvailable] = useState([]);
  const [time, setTime] = useState(0);
  const [logged, setLog] = useState(true);
  const [tourFinished, setTourFinished] = useState(false);
  

  // getting the credentials from instructions page
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const credentials = [searchparams.get("name"), searchparams.get("email")];

  // stores all the questions available

  // load quesn from db on login
  useEffect(() => {
    if (!tourFinished) {
      initializeDriver();
    } else {
      // Otherwise, call getSession and loadQuesn
      loadQuesn();
      getSession();
    }
  }, [tourFinished]);

  async function getSession() {
    const session = await axios.get(
      `https://democode.truliacare.com/backend/session/`,
      { withCredentials: true }
    );
    
    if(!session.data.found  && credentials[1] !== 'satin@kratin.co.in'){
      setLog(false);
      localStorage.setItem("text", "You're already logged in Somewhere!")
    }else{
      setLog(true)
    }
  }
  // check for session change

  getSession();

  // load quesiton
  async function loadQuesn() {
    // gets the data from quesnDb
    const q = await axios.get(`https://democode.truliacare.com/backend/`);

    // get the select quesn drop down element (when modified)
    // var selectList = document.getElementById('select-quesn')

    // add the quesn names to the drop down (when options in questions needed)
    // for(let i=0; i<q.data.length; i++){
    //   // add all quesns to an array
    //   // so can later select
    //   // as per the drop down value
    //   quesns_available.push(q.data[i])
    //   // create options
    //   var option = document.createElement("option");
    //   // enumerate options values as question
    //   option.setAttribute("value", i);
    //   option.text = q.data[i].quesn_name;
    //   selectList.appendChild(option)
    // }

    // get the data from the question
    question.splice(0, 1, q.data[0]);

    // on page load get the question
    // add that to allocatedQuesnDb
    // on page reload get the quesn from db
    // false for estting no update in db for allocatedQuesndb
    const submit_allocatedQuesn = createSubmission(false);

    axios
      .post("https://democode.truliacare.com/backend/aq", submit_allocatedQuesn)
      .then((response) => {
        // on first load of page
        // the post methods responds with Submitted!
        // so on first load set the question
        // as received from quesnDb
        if (response.data.message === "Submitted!") {
          // set when quesn choice is enabled
          // question.splice(0, 1, q.data[0]);
          // set when random quesn is needed
          question.splice(
            0,
            1,
            q.data[Math.floor(Math.random() * q.data.length)]
          );
          // submit the access time with email
          var submit_time = {
            email: credentials[1],
            time: parseInt(question[0].quesn_time) * 60,
            posttime: Date.now(),
          };
          axios
            .post("https://democode.truliacare.com/backend/time", submit_time)
            .then((res) => {});
        } else {
          // set the quesn as recieved from
          // allocatedQuensDb
          // on page refresh
          // or another login from same email
          question.splice(0, 1, response.data);
        }
      });
  }

  // // prevent right click
  // document.addEventListener('contextmenu', event => event.preventDefault());
  // prevent copy paste
  document.body.addEventListener(
    "keydown",
    function (ev) {
      // function to check the detection
      ev = ev || window.event; // Event object 'ev'
      var key = ev.which || ev.keyCode; // Detecting keyCode

      // Detecting Ctrl
      var ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;

      // If key pressed is V and if ctrl is true.
      if (key === 86 && ctrl) {
        // print in console.
        ev.preventDefault();
        // console.log("Ctrl+V is pressed.");
      } else if (key === 67 && ctrl) {
        // If key pressed is C and if ctrl is true.
        // print in console.
        ev.preventDefault();
        // console.log("Ctrl+C is pressed.");
      }
    },
    false
  );

  function createSubmission(change_value) {
    var submit_allocatedQuesn = {
      name: credentials[0],
      email: credentials[1],
      qname: question[0].quesn_name,
      statement: question[0].quesn_stmt,
      definition: question[0].quesn_def,
      constraint: question[0].quesn_constraint,
      example: question[0].quesn_example,
      explanation: question[0].quesn_explanation,
      time: question[0].quesn_time,
      inputs: question[0].inputs,
      expectedOutputs: question[0].expected_outputs,
      change: change_value,
    };
    return submit_allocatedQuesn;
  }
  // for the monaco editor
  // set the defaultvalue
  // and code for on page load
  if (resetDefaultValue) {
    setcode(defaultValue);
    setResetDefaultValue(false);
  }
  // options for monaco editor

  const options = {
    minimap: { enabled: false },
    selectOnLineNumbers: false,
    wordWrap: "on",
    quickSuggestions: false,
    contextmenu: false
  };

  // variables for extraction of
  // question from the response
  // and customization
  var problem_name,
    statemments,
    constraints,
    examples,
    explanations,
    input,
    expOutput;

  async function getTime() {
    // gets the data from quesnDb
    const time = await axios.get(
      `https://democode.truliacare.com/backend/time/?email=${credentials[1]}`
    );
    var t = time.data.time;
    var pt = time.data.post_time;
    var updated_time = t - Math.floor((Date.now() - pt) / 1000);
    setTime(updated_time);
  }

  // if question doesn't contain undefined
  if (question[0] !== undefined) {
    problem_name = question[0].quesn_name;
    // problem_stmt = question[0].quesn_stmt;
    // breaking statements with new line
    // and putting them in new p tags
    // as p tags don't read newline character
    statemments = [];
    question[0].quesn_stmt
      .split(".")
      .forEach((d, i) =>
        statemments.push(d !== "" ? <p key={i}>{d}.</p> : null)
      );
    constraints = [];
    question[0].quesn_constraint
      .split("\n")
      .forEach((d, i) =>
        constraints.push(d !== "" ? <li key={i}>{d}</li> : null)
      );
    examples = [];
    question[0].quesn_example
      .split("\n")
      .forEach((d, i) => examples.push(d !== "" ? <li key={i}>{d}</li> : null));
    explanations = [];
    question[0].quesn_explanation
      .split("\n")
      .forEach((d, i) =>
        explanations.push(d !== "" ? <li key={i}>{d}</li> : null)
      );
    // the time we get is in minutes,
    // but in Header the input is in seconds
    getTime();

    // create input and expected output array from inputs from question
    // for multiple inuts and outputs
    input = question[0].inputs.split(",");
    expOutput = question[0].expected_outputs.split(",");
  }

  // handles the custom theme of editor
  // after mount
  function handleEditorDidMount(editor, monaco) {
    import("monaco-themes/themes/Night Owl.json").then((data) => {
      monaco.editor.defineTheme("myTheme", data);
      setIsThemeLoaded(true);
    });
  }

  // change editor code
  function onChange(newValue) {
    localStorage.setItem("code", newValue);
    setcode(newValue);
  }

  // when the editor is refreshed
  // reset its default value
  function isSetDefaultValue(d) {
    setResetDefaultValue(d);
  }

  // Setting language
  // and preset code of each laguage
  function chooseLanguage(lang) {
    // lang is used to refer in switch case
    setLang(lang);
    // preset/default code for each lang
    var default_code = `//Let's Code`;

    switch (lang) {
      case "c":
        default_code += `
#include <stdio.h>

int main() {
    int num1, num2, result;

    // Taking user input
    scanf("%d", &num1);
    scanf("%d", &num2);

    // Calculating the sum
    result = num1 + num2;

    // Printing the result
    printf("result - %d", result);

    return 0;
}
`;
        break;

      case "cpp":
        default_code += `
#include <iostream>

using namespace std;

int main() {
    int num1, num2, result;
    // Taking user input
    cin >> num1;
    cin >> num2;

    // Calculating the sum
    result = num1 + num2;

    // Printing the result
    cout << "result - " << result << endl;

    return 0;
}
      `;
        break;

      case "java":
        default_code += `
// Keep the class name Main
import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        
        // Taking user input
        int num1 = scanner.nextInt();
        int num2 = scanner.nextInt();

        // Calculating the sum
        int result = num1 + num2;

        // Printing the result
        System.out.println("result - " + result);

        scanner.close();
    }
}
        `;
        break;

      case "javascript":
        default_code += `
console.log("Hello World")
        `;
        break;

      case "python":
        default_code = `
# Taking input for the first number
num1 = input()
# Taking input for the second number
num2 = input()

num1 = int(num1)
num2 = int(num2)

# Calculating the sum
result = num1 + num2

# Printing the result
print("result -", result)
        `;
        break;

      case "csharp":
        default_code += `
using System;

class Program
{
    static void Main()
    {
        // Taking input for the first number
        string num1Str = Console.ReadLine();

        // Taking input for the second number
        string num2Str = Console.ReadLine();

        int num1 = Convert.ToInt32(num1Str);
        int num2 = Convert.ToInt32(num2Str);

        // Calculating the sum
        int result = num1 + num2;

        // Printing the result
        Console.WriteLine("result - " + result);
    }
}
        `;
        break;

      case "go":
        default_code += `
package main

import (
	"fmt"
	"strconv"
)
func main() {
	// Taking input for the first number
	var num1Str string
	fmt.Scanln(&num1Str)

	// Taking input for the second number
	var num2Str string
	fmt.Scanln(&num2Str)

	// Parsing the input to integers
	num1, _ := strconv.Atoi(num1Str)
	num2, _ := strconv.Atoi(num2Str)

	// Calculating the sum
	result := num1 + num2

	// Printing the result
	fmt.Println("result -", result)
}
        `;
        break;

      default:
        break;
    }
    // set the preset code as default code of editor
    // after switch case
    setcode(default_code);
    setDefaultValue(default_code);
  }

  // choose question
  // when needed
  // function chooseQuestion(choice){
  //   // set the quesn as choosen
  //   question.splice(0, 1, quesns_available[parseInt(choice.target.value)])

  //   // set the allocatedQuesndb for refresh setup
  //   const submit_allocatedQuesn = createSubmission(true);
  //   axios
  //     .post("https://democode.truliacare.com/backend/aq", submit_allocatedQuesn)
  //     .then((response) => {console.log(response)})

  // }

  // when allocated time for question
  // exhausted
  // force logout
  function forceLogout(logout) {
    
      localStorage.setItem("text", "You've already logged out!")
    
    // if logout
    // set the submission data
    if (logout) {
      const submission = {
        name: credentials[0],
        email: credentials[1],
        code: localStorage.getItem("code"),
        codingTime: `Time Exhausted`,
        status: status,
      };
      // post the code into submissionDb
      axios
        .post("https://democode.truliacare.com/backend/submission", submission)
        .then((response) => {});

      // alert the user for force logout
      window.alert("Demo Completed");
      // go to submission page
      var submit_log = {
        email: credentials[1],
        log: false,
        change: true,
        deleteAll: false,
      };
      axios
        .post("https://democode.truliacare.com/backend/logout", submit_log, {
          withCredentials: true,
        })
        .then((res) => {
          // console.log(res);
          // after submission
          // navigate to submission page
          // while sending the status
          navigate({
            pathname: "/submit",
            search: createSearchParams({
              stat: status,
            }).toString(),
          });
        });
    }
  }

  function preventCP(e) {
    e.preventDefault();
    return false;
  }

  //driver.js element
  function initializeDriver() {
    const driverObj = driver({
      showProgress: true,
      allowClose: true,
      showButtons: ["next", "previous", "close"],
      steps: [
        {
          element: ".quesn",
          popover: {
            title: "Problem Statement",
            description:
              "The problem statement to be solved will appear here.",
            side: "right",
            align: "start",
          },
        },
        {
          element: "#select-language",
          popover: {
            title: "Programming Languages",
            description:
              "You can choose from different programming languages to solve the problem.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: ".editor-div",
          popover: {
            title: "Code Editor",
            description:
              "You can write your code here for the problem in your chosen programming langauge",
            side: "right",
            align: "start",
          },
        },
        {
          element: ".timer",
          popover: {
            title: "Timer",
            description:
              "You can see the time remaining for the question here",
            side: "left",
            align: 'start',
          },
        },
        {
          element: ".runBtn",
          popover: {
            title: "Test Cases",
            description:
              "You can run the testcases defined for the problem from here.",
            side: "left",
            align: "start",
          },
        },
        {
          element: ".custom-input",
          popover: {
            title: "Custom Input",
            description: "You can write your own custom input and test it.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: ".submitBtn",
          popover: {
            title: "Submit",
            description:
              "Once you are confident with your code you can submit your code from here.",
            side: "left",
            align: "start",
          },
        },
        {
          element: ".logoutBtn",
          popover :{
            title: "Logout",
            description:
              "After you have submitted your code, you can logout from here and your last submission will be recorded.",
            side: "left",
            align: "start",
          },
        },
        {
          popover: {
            title: "Happy Coding",
            description:
              "You can start your test now.",
          },
        },
      ],
      onDestroyStarted: () => {
        // eslint-disable-next-line no-restricted-globals
        if (driverObj.popover || confirm("The timer will start once you click OK.")) {
          driverObj.destroy();
          setTourFinished(true)
        }
      }
    });
    driverObj.drive();
  }
  
  return (
    <>
      {logged ? (
        <div onCopy={preventCP} onPaste={preventCP}>
          <Header
            time={time}
            logout={forceLogout}
            submitTime={setSubmitTime}
            credentials={credentials}
            status={status}
            tourFinished={tourFinished}
          />
          <div className="container">
            <Split className="split">
              <div className="quesn">
                <div className="choose_problem">
                  {/* when needed */}
                  {/* <select
          id="select-quesn"
          className="editor-element-height"
          onChange={chooseQuestion}
        >
          <option>Change Question</option>
        </select> */}
                </div>
                <div className="problem_name">
                  <h2>{problem_name}</h2>
                </div>
                <div className="problem_stmt">
                  <h3>Problem Statement</h3>
                  <div>{statemments}</div>
                </div>
                <div className="problem_const">
                  <h3>Constraints</h3>
                  <ul>{constraints}</ul>
                </div>
                <div className="problem_exmpl">
                  <h3>Examples</h3>
                  <p>{examples}</p>
                </div>
                <div className="problem_explain">
                  <h3>Explanation</h3>
                  <p>{explanations}</p>
                </div>
              </div>
              <div className="editor-div editor-bgclr">
                <EditorHeader
                  setDefaultValue={isSetDefaultValue}
                  setLanguage={chooseLanguage}
                  lang_id={setLangId}
                />
                <div className="warning">
                  <FontAwesomeIcon icon={faCircleExclamation} /> Don't print
                  anything except result!
                </div>
                <div>
                  <ControlledEditor
                    language={lang}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    value={code}
                    onMount={handleEditorDidMount}
                    options={options}
                    theme={isThemeLoaded ? "myTheme" : "dark"}
                    height="85vh"
                  />
                </div>
              </div>
              <EditorFooter
                code={code}
                lang_id={lang_id}
                input={input}
                expOutput={expOutput}
                credentials={credentials}
                submitTime={submitTime}
                totalTime={time}
                status={setStatus}
              />
            </Split>
          </div>
        </div>
      ) : (
        <Pagenotfound email={credentials[1]} />
      )}
    </>
  );
};

export default CodeEditor;

import React, { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import jwt from "jwt-decode";

function CheckLogin() {
  const APP_URL = window.location.origin;
  const LOGOUT_URL = `https://uhx01.kratinmobile.com/uhx/identity/auth/dologout?callback=${APP_URL}`;

  const navigate = useNavigate();
  const location = useLocation();

  // navigate function
  const getTokenData = () => {
    const params = new URLSearchParams(location.hash);
    const hashData = JSON.parse(JSON.stringify([...params.entries()]));
    return jwt(hashData[1][1]);
  };

  // save credentials
  async function saveUser(response) {
    const tokenData = getTokenData();
    var email = tokenData.email;

    if (email === "satin@kratin.co.in") {
      // If email is specific, route to instructions
      navigate({
        pathname: "/allowlogin",
        search: createSearchParams({
          name: tokenData.given_name + " " + tokenData.family_name,
          email: email,
        }).toString(),
      });
    } else if (email.endsWith("kratin.co.in")) {
      // If email ends with "kratin.co.in", route to allowlogin
      navigate({
        pathname: "/allowlogin",
        search: createSearchParams({
          name: tokenData.given_name + " " + tokenData.family_name,
          email: email,
        }).toString(),
      });
    } else {
      // If email doesn't match any specific condition, proceed with regular login process
      localStorage.removeItem("code");
      axios
        .delete(`https://democode.truliacare.com/backend/delete/?email=${email}`, {
          withCredentials: true,
        })
        .then((res) => {});

      var submit_log = {
        email: email,
        log: true,
        change: true,
      };
      axios
        .post("https://democode.truliacare.com/backend/session", submit_log, {
          withCredentials: true,
        })
        .then((response) => {});

      await new Promise((resolve) => setTimeout(resolve, 500));

      navigate({
        pathname: "/instructions",
        search: createSearchParams({
          name: tokenData.given_name + " " + tokenData.family_name,
          email: email,
        }).toString(),
      });
    }
  }

  // show error
  const respondError = (err) => {
    window.alert("Not a valid email!");
  };

  useEffect(() => {
    saveUser();
  }, []);

  return <></>;
}

export default CheckLogin;

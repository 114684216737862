import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Card, Input, Button, AutoComplete } from 'antd'; // Import AutoComplete component from antd

const AllowLogin = () => {
  const [email, setEmail] = useState('');
  const [checkEmailMessage, setCheckEmailMessage] = useState('');
  const [checkedEmail, setCheckedEmail] = useState(null);
  const [allowLoginMessage, setAllowLoginMessage] = useState('');
  const [suggestedEmails, setSuggestedEmails] = useState([]); // State to store suggested emails

  const checkEmail = async () => {
    try {
      const response = await axios.post('https://democode.truliacare.com/adminbackend/check_email', { candidate_email: email });
      if (response && response.data) {
        setCheckedEmail(response.data);
        setCheckEmailMessage('');
      } else {
        setCheckedEmail(null);
        setCheckEmailMessage('No document found for the provided email.');
      }
    } catch (error) {
      setCheckedEmail(null);
      setCheckEmailMessage('An error occurred while checking the email.');
    }
  };

  const allowLogin = async () => {
    try {
      const response = await axios.post('https://democode.truliacare.com/adminbackend/allow_login', { candidate_email: email });
      if (response && response.data && response.data.message) {
        setAllowLoginMessage(response.data.message);
      } else {
        setAllowLoginMessage('Unexpected response from the server.');
      }
    } catch (error) {
      setAllowLoginMessage('Data not found');
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    // Call suggest email API when the user types
    suggestEmail(value);
  };

  const suggestEmail = async (input) => {
    try {
      const response = await axios.post('https://democode.truliacare.com/adminbackend/suggest_email', { candidate_email: input });
      if (response && response.data && response.data.emails) {
        setSuggestedEmails(response.data.emails);
      } else {
        setSuggestedEmails([]);
      }
    } catch (error) {
      console.error('Error suggesting emails:', error);
      setSuggestedEmails([]);
    }
  };

  return (
    <div className="login-container">
      <Card style={{ width: 700, margin: '10px auto', textAlign: 'center' }}>
        <img src={require("../assets/kratin_vertical_logo.png")} alt="Website Tour" style={{ maxWidth: "100px", maxHeight: "200px" }} />
        <h2 style={{ marginBottom: 20 }}>Code Assessment Admin</h2>
        <div className="login-form">
          <label>
            Email:
            <AutoComplete
              value={email}
              onChange={handleEmailChange}
              dataSource={suggestedEmails}
              style={{ width: '100%' }}
              placeholder="Enter email"
            />
          </label>
          <br />
          <Button type="primary" onClick={checkEmail} style={{ marginTop: 20 }}>Check Email</Button>
          {checkEmailMessage && <p>{checkEmailMessage}</p>}
          {checkedEmail && (
            <div style={{ marginTop: 20 }}>
              <div style={{ maxHeight: '200px', overflowY: 'auto', textAlign: 'left' }}>
                <p><b>Email:</b> {checkedEmail.candidate_email}</p>
                <p><b>Name:</b> {checkedEmail.candidate_name}</p>
                <p><b>Last attempt:</b> {checkedEmail.submit_time}</p>
                <p><b>Status:</b> {checkedEmail.status}</p>
                <p><b>Code:</b></p>
                <pre><code>{checkedEmail.code}</code></pre>
                <p><b>Inputs:</b> {checkedEmail.inputs}</p>
                <p><b>Expected Outputs:</b> {checkedEmail.expected_outputs}</p>
                <p><b>Question allocated:</b></p>
                <p>{checkedEmail.question_name}</p>
                <p>{checkedEmail.question_statement}</p>
              </div>
              <Button type="primary" onClick={allowLogin}>Allow Login</Button>
              {allowLoginMessage && <p>{allowLoginMessage}</p>}
            </div>
          )}
          <div style={{ marginTop: 20 }}>
            <Link to="/instructions">
              <Button>Login to the demo assessment</Button>
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AllowLogin;

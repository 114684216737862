import React, { useState, useEffect } from "react";
import axios from "axios";
import LogoutBtn from "./LogoutBtn";
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";

const Header = ({ time, logout, submitTime, credentials, status, tourFinished }) => {
  //console.log("tourFinished value:", tourFinished);
  const [sec, setSec] = useState(0);
  const [min, setMin] = useState(0);
  var s = time;

  useEffect(() => {
    //console.log("tourFinished value:", tourFinished);
    // run the timer
    if (tourFinished) {
      //console.log("tourFinished is true");
      var minute = setInterval(() => {
        submitTime(s);

        // when time out
        // set logout true
        // for force logout
        if (s === 1) {
          logout(true);
          clearInterval(minute);
        }
        // pause when running
        s--;
        // set seconds and minutes
        setSec(s - Math.floor(s / 60) * 60);
        setMin(Math.floor(s / 60));
      }, 1000);

      return () => clearInterval(minute);
    }
  }, [time, tourFinished, logout, s, submitTime]);

  return (
    <div className="header">
      <div className="header-image"></div>
      <h1>Code Assessment Demo</h1>
      <div className="timer">
        {min}min : {sec}sec
        <LogoutBtn credentials={credentials} status={status} />
      </div>
    </div>
  );
};
export default Header;
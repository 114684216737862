import "./App.css";
import CodeEditor from "./Components/Editor";
import Login from "./Components/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Submission from "./Components/Submission";
import Instructions from "./Components/Instructions";
import axios from "axios";
import { useState } from "react";
import CheckLogin from "./Components/CheckLogin";
import AllowLogin from "./Components/AllowLogin";


function App() {
  // prevent right click
  document.addEventListener('contextmenu', event => event.preventDefault());

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/checklogin" element={<CheckLogin />} />
        <Route path="/allowlogin" element ={<AllowLogin/>} />
        <Route path="/instructions" element={<Instructions />} />
        <Route path="/assignment" element={<CodeEditor />} />
        <Route path="/submit" element={<Submission />} />
      </Routes>
    </Router>
  );
}

export default App;
